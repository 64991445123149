import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "swiper/css";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import clsx from "clsx";
import LazyImage from "../../components/v4/LazyLoadImage/LazyImage";
import { useInView } from "react-intersection-observer";

import businnesImg1 from "../../images/v4/tricovePage/images/businnesImg1.png";
import businnesImg2 from "../../images/v4/tricovePage/images/businnesImg2.png";
import businnesImg3 from "../../images/v4/tricovePage/images/businnesImg3.png";

import IMDA_SME_Large from "../../images/v2/psg/IMDA_SME_Large.webp";

import whiteCheckCircle from "../../images/v4/whatsappPage/icons/whiteCheckCircle.svg";

import partnerIcon1 from "../../images/v4/aiPage/icons/partnerIcon1.png";
import partnerIcon2 from "../../images/v4/aiPage/icons/partnerIcon2.png";
import partnerIcon3 from "../../images/v4/aiPage/icons/partnerIcon3.png";
import partnerIcon5 from "../../images/v4/aiPage/icons/partnerIcon5.png";
import partnerIcon6 from "../../images/v4/aiPage/icons/partnerIcon6.png";
import partnerIcon7 from "../../images/v4/aiPage/icons/partnerIcon7.png";
import partnerIcon8 from "../../images/v4/aiPage/icons/partnerIcon8.png";
import FaqSection from "../../components/v4/FaqSection/FaqSection";

import companyLogo from "../../images/v4/logos/companyLogo.svg";

import experienceImg1 from "../../images/v4/whatsappPage/images/experienceImg1.png";
import experienceImg2 from "../../images/v4/whatsappPage/images/experienceImg2.png";
import experienceImg3 from "../../images/v4/whatsappPage/images/experienceImg3.png";
import experienceImg4 from "../../images/v4/whatsappPage/images/experienceImg4.png";

import sellImage1 from "../../images/v4/tricovePage/icons/sellImage1.svg";
import sellImage2 from "../../images/v4/tricovePage/icons/sellImage2.svg";
import sellImage3 from "../../images/v4/tricovePage/icons/sellImage3.svg";
import sellImage4 from "../../images/v4/tricovePage/icons/sellImage4.svg";

import greenCheckIcon from "../../images/v3/icons/GreenCheckIcon.webp";

import { Collapse, Divider, makeStyles } from "@material-ui/core";

import Link from "../../components/v2/Link";

import whatsappBusinessPartner from "../../images/v3/singaporeCrmHomepage/whatsappBusinessPartner.webp";
import metaBusinessPartner from "../../images/v3/singaporeCrmHomepage/metaBusinessPartner.webp";

import SEO from "../../components/SEO";

import tricoveBgPage from "../../images/v4/tricovePage/images/tricoveBgPage.png";
import trivacoPageGreenBg from "../../images/v4/tricovePage/images/trivacoPageGreenBg.png";
import callImg from "../../images/v2/campaigns/callNew.svg";

export const conversionEventOnCall = () => {
  if (typeof window.gtag === "function")
    window.gtag("event", "conversion", {
      send_to: "AW-615460128/prVoCM7gjuwBEKDavKUC",
    });
};

const useStyles = makeStyles((theme) => ({
  broadcastContainer: {
    background: "#210248",
    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "32px",
      width: "70%",
      margin: "0 auto",
      textAlign: "center",
    },
    "@media only screen and (max-width: 600px)": {
      "& .centerContainer": {
        width: "100%",
      },
    },
  },
  broadcastBox: {
    display: "flex !important",
    padding: "32px",
    background: "#009249",
    alignItems: "flex-start",
    borderRadius: "20px",
    justifyContent: "flex-start",
    minWidth: "85%",
    maxWidth: "85%",
    transition: "0.3s ease",
    "&.active": {
      transform: "scale(1) !important",
    },
    "&.inActive": {
      transform: "scale(0.8) !important",
    },
    "@media only screen and (max-width: 600px)": {
      padding: "15px",
      gap: "15px",
      flexDirection: "column",
      minHeight: "730px",
    },
  },
  cardPointer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    "@media only screen and (max-width: 600px)": {
      alignItems: "baseline",
    },
  },
  broadcastContainerInner: {
    width: "100%",
    display: "flex",
    position: "relative",
    alignItems: "flex-start",
    gap: "30px",
    padding: "0 4rem 4rem !important",
    overflow: "auto",
    justifyContent: "flex-start",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "@media only screen and (max-width: 600px)": {
      padding: "0 15px 40px !important",
    },
    "@media only screen and (min-width: 1900px)": {
      padding: "0 19rem 6rem !important",
    },
  },
  broadcastLeftContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "60%",
    gap: "32px",
    textAlign: "left",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  broadcastRightContainer: {
    width: "40%",
    "& img": {
      width: "100%",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      "& img": {
        width: "100%",
      },
    },
  },
  broadcastImg: {
    "& img": {
      width: "100%",
      position: "relative",
      bottom: "0",
    },
  },
  partnerSlide: {
    width: "185px !important",
    "& img": {
      width: "185px",
      filter: "brightness(1)",
    },
  },
  partnerSlider: {
    padding: "10px 0 80px !important",
  },
  pointerImg: {
    "& img": {
      width: "28px",
    },
    "@media only screen and (max-width: 600px)": {
      "& img": {
        width: "10px",
      },
    },
  },
  pointers: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "20px",
  },
  aIPowered: {
    background: `url(${trivacoPageGreenBg})`,
    backgroundSize: "cover",
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    "& .centerContainer": {
      textAlign: "center",
    },
  },
  statsContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "60px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "15px",
    },
  },
  statsBox: {
    width: "24%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "20px",
    background: "#0020205e",
    borderRadius: "30px",
    padding: "20px",
    minHeight: "320px",
    backdropFilter: "blur(30px)",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      gap: "15px",
      minHeight: "auto",
    },
    "& .border": {
      border: "1px solid #fefefe1c",
    },
  },
  statsBoxBody: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "5px",
    "& p": {
      color: "#B9FFE1",
    },
    "& p:last-child": {
      color: "#D7EFE5",
    },
    "@media only screen and (max-width: 600px)": {
      width: "70%",
      "& ul": {
        paddingLeft: "0",
      },
      "& li": {
        marginBottom: "10px",
      },
    },
  },
  statsFont: {
    fontSize: "80px",
    color: "#B9FFE1",
    display: "flex",
    alignItems: "baseline",
    "@media only screen and (max-width: 600px)": {
      fontSize: "60px",
    },
  },
  supportSystem: {
    background: "#1D0240",
    borderRadius: "40px",
    paddingBottom: "200px",
    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "32px",
      width: "80%",
      margin: "0 auto",
      textAlign: "center",
    },
    "@media only screen and (max-width: 600px)": {
      "& .centerContainer": {
        width: "100%",
      },
    },
  },
  tabsContainer: {
    width: "80%",
    marginTop: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px",
    border: "2px solid #DBDBDB",
    borderRadius: "40px",
    background: "#fff",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      padding: "5px",
      marginTop: "0px",
      overflow: "auto",
      gap: "15px",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tab: {
    width: "33%",
    background: "",
    padding: "18px",
    background: "transparent",
    borderRadius: "40px",
    background: "transparent",
    transition: "0.3s ease",
    cursor: "pointer",
    "&.active": {
      color: "white",
      background:
        "linear-gradient(90deg, #FF682A 0%, #FF7056 35%, #FF682A 73%, #FF550F 100%)",
    },
    "@media only screen and (max-width: 600px)": {
      minWidth: "max-content",
      padding: "10px",
    },
  },
  supportBox: {
    top: "400px",
    width: "100%",
    display: "flex",
    padding: "40px",
    position: "absolute",
    background: "#5e278ae0",
    alignItems: "center",
    borderRadius: "20px",
    backdropFilter: "blur(30px)",
    justifyContent: "flex-start",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      width: "94%",
      padding: "15px",
      top: "270px",
    },
  },
  smeSection: {},
  smeImage: {
    background: "white",
    width: "100%",
    cursor: "pointer",
  },
  smeSectionInner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    "& p": {
      width: "70%",
      textAlign: "center",
      "@media only screen and (max-width: 600px)": {
        width: "100%",
      },
    },
  },
  commonPageFooterSection: {
    background: "linear-gradient(90deg, #003561 0%, #44007B 100%)",
    "& .centerContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "32px",
      width: "80%",
      margin: "0 auto",
      textAlign: "center",
    },
    "@media only screen and (max-width: 600px)": {
      "& .centerContainer": {
        width: "100%",
      },
    },
  },
  headerContainer: {
    width: "100%",
    margin: "auto",
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    zIndex: "100",
    transition: "0.5s ease",
  },
  headerContainerInner: {
    position: "relative",
    width: "100%",
    padding: "10px 32px",
    maxWidth: "1200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "rgb(7 34 53 / 90%)",
    backdropFilter: "blur(10px)",
    borderRadius: "0px 0px 16px 16px",
    transition: "0.3s ease",
    "&.glassEffect": {
      background: "rgb(7 34 53 / 70%)",
      backdropFilter: "blur(7px) contrast(1)",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
    "&.darkBackground": {
      background: "rgb(7 34 53 / 100%)",
    },
    "@media only screen and (max-width: 600px)": {
      padding: "10px 15px",
    },
  },
  rightNavigation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "20px",
    "& a": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: "5px",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
        color: "white",
      },
    },
    "& .primaryButton": {
      zIndex: "2",
      "@media only screen and (max-width: 600px)": {
        padding: "10px 15px",
        fontSize: "12px",
      },
    },
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "5px",
      "& p:first-child":{
        display:'none'
      }
    },
  },
  pageFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",

    background: "linear-gradient(90deg, #011D32 0%, #00182B 100%)",
    "& p": {
      color: "#9097A6",
      "@media only screen and (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    "& a": {
      color: "#9097A6",
      "@media only screen and (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    "& .container": {
      padding: "30px 0",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "@media only screen and (max-width: 600px)": {
        gap: "10px",
        padding: "15px",
        alignItems: "flex-start",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap",
      },
    },
  },
  whatsappSectionContainer: {
    backgroundImage: `url(${tricoveBgPage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "70px 0 0 0",
    "@media only screen and (max-width: 600px)": {
      padding: "40px 0 0 0",
    },
  },
  whatsappContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "25px",
    "@media only screen and (max-width: 600px)": {
      flexDirection: "column",
      gap: "20px",
    },
  },
  whatsappContainerLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "51%",
    gap: "25px",
    flexDirection: "column",
    "& .highlighterText": {
      background: "linear-gradient(90deg, #084FFF 0%, #00BFA5 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      gap: "15px",
    },
  },
  whatsappContainerRight: {
    width: "45%",
    display: "flex",
    flexDirection: "column",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  formContainer: {
    width: "100%",
    background: "#fff",
    boxShadow: "0px 0px 21px #00000029",
    borderRadius: "0 0 20px 20px",
    padding: "0px",
    height: "auto",
    minHeight: "auto",
    "@media only screen and (max-width: 600px)": {
      height: "580px",
      padding: "0",
    },

    overflow: "visible",
  },
  tagsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    width: "70%",
    gap: "12px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  tag: {
    padding: "10px",
    borderRadius: "20px",
    background: "#CBF0EA5C",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "10px",
  },
  formHeader: {
    textAlign: "center",
    padding: "20px",
    background: "linear-gradient(90deg, #004885 0%, #822AC9 100%)",
    borderRadius: "20px 20px 0 0",
    boxShadow: "0px 0px 21px #00000029",
    "& span": {
      color: "#FFC7B0",
    },
  },
  experienceContainer: {
    "& .centerContainer": {
      width: "70%",
      margin: "0 auto",
      textAlign: "center",
      "@media only screen and (max-width: 600px)": {
        width: "100%",
      },
    },
  },
  experienceInnerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "40px",
  },
  experienceContainerLeft: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "24px",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
      marginTop: "30px",
    },
  },
  faqBox: {
    borderBottom: "1px solid #C4E4DC",
    paddingBottom: "14px",
    position: "relative",
    transition: "0.3s ease",
    "&::after": {
      left: "0",
      bottom: "-2px",
      width: "0",
      height: "3px",
      content: '""',
      position: "absolute",
      transition: "0.3s ease",
      borderRadius: "10px",
      background:
        "linear-gradient(90deg, #25D366 0%, #08B57B 66%, #72E9B2 100%)",
    },
    "&.active::after": {
      transition: "8s ease",
      width: "100%",
    },
  },
  faqHeader: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  experienceContainerRight: {
    width: "40%",
    minHeight: "450px",
    "& img": {
      width: "100%",
      height: "100%",
    },
    "@media only screen and (max-width: 600px)": {
      display: "none",
    },
  },
  downArrow: {
    transition: "0.3s ease",
    "&.active": {
      transform: "rotate(180deg)",
    },
  },
  body: {
    textAlign: "left",
    margin: "10px 0 0 ",
    "& img": {
      display: "none",
    },
    "@media only screen and (max-width: 600px)": {
      "& img": {
        display: "block",
        width: "100%",
        marginTop: "15px",
      },
    },
  },
  leftNavigation: {
    "@media only screen and (max-width: 600px)": {
      "& img": {
        width: "150px",
      },
    },
  },
  wpFaq: {
    "@media only screen and (max-width: 600px)": {
      "& section": {
        padding: "0px 0 0 !important",
      },
    },
  },
  rewards: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "flex-start",
    gap: "10px",
    boxShadow: "0px 0px 6px #00000029",
    padding: "8px 15px",
    borderRadius: "100px",
    width: "60%",
    background: "#fff",
    "& .border": {
      borderRight: "1px solid #DBDBDB",
      width: "0",
    },
    "& img": {
      width: "47%",
      "@media only screen and (max-width: 600px)": {
        width: "45%",
      },
    },
  },
  bannerSection: {
    "& .centerContainer": {
      textAlign: "center",
      "& img": {
        width: "56px",
        position: "relative",
        top: "10px",
        "@media only screen and (max-width: 600px)": {
          width: "16px",
          top: "3px",
        },
      },
      "@media only screen and (max-width: 600px)": {
        "& h2": {
          fontSize: "13px",
        },
        width: "100%",
        margin: "auto",
      },
    },
    "& .container": {
      padding: "130px 0",
      "@media only screen and (max-width: 600px)": {
        padding: "2rem 15px",
      },
    },
    width: "100%",
    "@media only screen and (min-width: 1900px)": {
      "& .container": {
        padding: "190px 0",
      },
    },
    // backgroundColor: "#00926A",
    // backgroundImage: `url(${bannerBgImg2})`,
    // backgroundSize: "cover",
    // backgroundRepeat: "repeat-x",
    // backgroundPosition: "center",
    // WebkitAnimation: "slidein 80s",
    // animation: "slidein 10s",
    // WebkitAnimationFillMode: "forwards",
    // animationFillMode: "forwards",
    // WebkitAnimationIterationCount: "infinite",
    // animationIterationCount: "infinite",
    // WebkitAnimationDirection: "alternate",
    // animationDirection: "alternate",
    // backgroundSpacing: "10px", // Add spacing between repeated background images
  },
  trustedSection: {
    marginTop: "40px",
  },
  backgroundAnimation: {
    position: "absolute",
    width: "100%",
  },
  belowText: {
    width: "100%",
    textAlign: "right",
    color: "#8fb3a4",
    marginTop: "10px",
  },
  sellCard: {
    padding: "24px",
    borderRadius: "16px",
    display: "flex !important",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "24px",
    maxWidth: "360px !important",
    minHeight: "330px",
    "@media only screen and (max-width: 600px)": {
      maxWidth: "90% !important",
    },
  },
  salesList: {
    listStyle: "none",
    padding: "0",
    margin: "0",
    marginTop: "10px",
  },
  salesListItem: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "10px",
    marginBottom: "16px",
  },
  sellSwiper: {
    padding: "0 8rem 2rem !important",
    "@media only screen and (max-width: 600px)": {
      padding: "0 15px 40px !important",
    },
    "@media only screen and (min-width: 1900px)": {
      padding: "0 22rem 6rem !important",
    },
  },
  sellSmartHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "10px",
    width: "55%",
    "@media only screen and (max-width: 600px)": {
      width: "100%",
    },
  },
  sellSection: {
    backgroundImage: `url(${tricoveBgPage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  belowBroadcast: {
    "& .container": {
      paddingTop: "0",
      "& p": {
        width: "60%",
        margin: "auto",
        textAlign: "center",
        "@media only screen and (max-width: 600px)": {
          width: "100%",
        },
      },
    },
  },
}));

const TricoPage = () => {
  const classes = useStyles();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [lastScrollY, setLastScrollY] = useState(0);
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setScrollPosition(currentScrollY);
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        // setSubMenuOpen(false);
        // setIsVisible(false);
      } else {
        // setSubMenuOpen(false);
        // setIsVisible(true);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  const sellSmartList = [
    {
      icon: sellImage1,
      heading: "Workflow automation",
      points: [
        "Eliminate repetitive tasks and focus on what matters.",
        "Automate follow-ups, task reminders, and lead assignments.",
        "Close deals faster with the efficiency of a top CRM tool.",
      ],
      bgColor: "#F9F1FF",
    },
    {
      icon: sellImage2,
      heading: "Omnichannel communication",
      points: [
        "Sync interactions across WhatsApp, email, social media, and more.",
        "Unify all channels for consistent and efficient customer engagement.",
        "Personalise messages across the channels to drive sales.",
      ],
      bgColor: "#F4FAFF",
    },
    {
      icon: sellImage3,
      heading: "Analytics and insights",
      points: [
        "Access insights into leads, sales, activities, messages, and more.",
        "Customise the dashboard to suit your needs and preferences.",
        "Gain a comprehensive overview of your team performance.",
      ],
      bgColor: "#F1FFF9",
    },
    {
      icon: sellImage4,
      heading: "Designed for SMEs",
      points: [
        "Built to simplify operations for small and medium-sized businesses.",
        "Avail up to 50% PSG (Productivity Solutions Grant) support.",
        "24/7 local support for growing businesses.",
      ],
      bgColor: "#FFF5F1",
    },
  ];

  const partners = [
    { icon: partnerIcon1, alt: "caffeine solutions - Pepper Cloud Customer" },
    { icon: partnerIcon2, alt: "cuscapi - Pepper Cloud Customer" },
    { icon: partnerIcon3, alt: "TechData - Pepper Cloud Customer" },
    { icon: partnerIcon5, alt: "sis - Pepper Cloud Customer" },
    {
      icon: partnerIcon6,
      alt: "mustard seed systems corporation - Pepper Cloud Customer",
    },
    {
      icon: partnerIcon7,
      alt: "Singapore Maritime Foundation - Pepper Cloud Customer",
    },
    { icon: partnerIcon8, alt: "urban moods - Pepper Cloud Customer" },
    { icon: partnerIcon1, alt: "caffeine solutions - Pepper Cloud Customer" },
    { icon: partnerIcon2, alt: "cuscapi - Pepper Cloud Customer" },
    { icon: partnerIcon3, alt: "TechData - Pepper Cloud Customer" },
    { icon: partnerIcon5, alt: "sis - Pepper Cloud Customer" },
    {
      icon: partnerIcon6,
      alt: "mustard seed systems corporation - Pepper Cloud Customer",
    },
    {
      icon: partnerIcon7,
      alt: "Singapore Maritime Foundation - Pepper Cloud Customer",
    },
    { icon: partnerIcon8, alt: "urban moods - Pepper Cloud Customer" },
  ];

  const broadcastList = [
    {
      heading: "AI-powered CRM to enhance productivity",
      points: [
        "Manage tasks, follow-ups, and records with AssistAI.",
        "Generate conversation summaries and modify replies using NLP.",
        "Address queries round-the-clock with the AssistAI chatbot.",
        "Train your chatbot with custom knowledge base.",
        "Reply to customers with just a click using generative AI.",
      ],
      img: businnesImg1,
      alt: "AI-powered CRM tool",
      background: "linear-gradient(180deg, #00B181 0%, #1448C4 100%)",
    },
    {
      heading: "WhatsApp CRM to streamline communication",
      points: [
        "Capture leads from website, messages, ads, and more.",
        "Send auto-replies with rich-media message templates.",
        "Promote products through WhatsApp broadcasts and catalogs.",
        "Create WhatsApp groups with clients directly from CRM.",
        "Offer end-to-end shopping experience with WhatsApp forms.",
      ],
      img: businnesImg2,
      alt: " WhatsApp CRM tool",
      background: "linear-gradient(180deg, #F77F4C 0%, #9227E9 100%)",
    },
    {
      heading: "All-in-one CRM to close more deals",
      points: [
        "Automate sales activities and repetitive tasks with automation.",
        "Customise pipelines, stages, and fields to fit your business needs.",
        "Get real-time sales reports and track team performance.",
        "Get realistic sales projections using forecasting capabilities.",
        "Build comprehensive customer profiles easily.",
      ],
      img: businnesImg3,
      alt: "Omnichannel CRM tool",
      background: "linear-gradient(180deg, #B74AF5 0%, #5E02A8 100%)",
    },
  ];
  const statsBoxContent = [
    {
      heading: "Reduction in response times",
      number: "70",
      content: "Handle customer inquiries quickly and efficiently.",
      color: "#FFDFD2",
    },
    {
      heading: "Increase in sales revenue",
      number: "57",
      content: "Drive growth with smarter lead management.",
      color: "#F1DFFF",
    },
    {
      heading: "Reduction in operational costs",
      number: "30",
      content: "Save time and resources with automated processes.",
      color: "#CEE9FF",
    },
    {
      heading: "Higher conversion rates",
      number: "20",
      content: "Boost engagement with targeted campaigns.",
      color: "#BEE7E7",
    },
  ];
  const faqs = [
    {
      question: "What is a CRM tool?",
      answer: (
        <>
          CRM, or Customer Relationship Management, is a software solution
          designed to help businesses manage interactions with current and
          prospective customers. It provides tools to organize customer data,
          improve communication, and enhance overall customer satisfaction.
          Learn more about CRM and how it can transform your business in our{" "}
          <a
            href="https://blog.peppercloud.com/complete-guide-to-crm-software/"
            target="_blank"
          >
            detailed guide
          </a>
          .
        </>
      ),
    },
    {
      question: "I’m an SME. How can a CRM tool benefit us?",
      answer:
        "A CRM tool is a valuable asset for SMEs, helping you streamline operations, improve customer relationships, and drive growth. By centralising all customer interactions, a CRM tool allows you to provide personalized service, automate workflows, and track leads efficiently. Features such as task reminders, automated follow-ups, sales pipelines, and real-time analytics empower you to save time and focus on expanding your business.",
    },
    {
      question: "What integrations does Pepper Cloud CRM support?",
      answer:
        "Pepper Cloud CRM connects with a variety of platforms, including WhatsApp, Facebook, Instagram, Google Workspace, Microsoft Office, Xero, Aircall, Zoom, and many others. These integrations ensure seamless collaboration and streamlined operations for your business.",
    },
    {
      question: "How secure is my data in Pepper Cloud CRM?",
      answer:
        "Data security is our top priority. Pepper Cloud CRM ensures protection through multi-level authentication and Secure Socket Layer (SSL) encryption. This robust security infrastructure guarantees safe communication and safeguards your data across all channels.",
    },
    {
      question: "Which grants can I avail through Pepper Cloud?",
      answer: (
        <>
          Pepper Cloud enables SMEs to access both the{" "}
          <b>Productivity Solutions Grant (PSG)</b> and the{" "}
          <b>Enterprise Development Grant (EDG)</b>. Additionally, we provide
          guidance to help you leverage applicable grants to grow your business.
          Learn more about government grants for SMEs in our guide,{" "}
          <a
            target="_blank"
            href="https://blog.peppercloud.com/accelerating-singapores-digital-transformation-edg-psg-ctoaas/"
          >
            A Guide to Singapore Government Grants
          </a>
          .
        </>
      ),
    },
    {
      question: "What is the PSG grant?",
      answer: (
        <>
          The <b>Productivity Solutions Grant (PSG)</b> is a pre-approved
          government initiative managed by Enterprise Singapore to support SMEs
          in adopting IT solutions like CRM, HR software, and accounting tools.
          It helps businesses enhance efficiency and optimize processes. Learn
          more about{" "}
          <a
            href="https://blog.peppercloud.com/ultimate-guide-to-productivity-solutions-grant/"
            target="_blank"
          >
            PSG and its benefits in our blog
          </a>
        </>
      ),
    },
    {
      question: "How can I apply for the PSG grant to avail Pepper Cloud CRM?",
      answer: (
        <>
          Here’s a step-by-step process for applying:
          <ul>
            <li>Contact our support team to request a quotation.</li>
            <li>
              Log in to the Business Grants Portal and submit your application.
            </li>
            <li>Upon approval, accept the Letter of Offer on the portal.</li>
            <li>
              Complete the CRM implementation and training within the stipulated
              time.
            </li>
            <li>
              Use the CRM for at least a month before submitting your claim.
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "Is my company eligible for the PSG grant?",
      answer: (
        <>
          Your company qualifies for the PSG grant if:
          <ul>
            <li>It is registered/incorporated in Singapore.</li>
            <li>It has at least 30% local shareholding.</li>
            <li>
              The group annual sales turnover does not exceed S$100 million, or
              the company employs no more than 200 workers.
            </li>
          </ul>
          Contact our sales team to verify your eligibility and guide you
          through the application process.
        </>
      ),
    },
    {
      question:
        "How does Tricove Tech contribute to implementing Pepper Cloud CRM?",
      answer:
        "Tricove Tech is a trusted implementation partner of Pepper Cloud CRM. They specialize in deploying our CRM solutions for SMEs, ensuring smooth integration, personalised training, and round-the-clock local support to help you maximise the benefits of the CRM tool.",
    },
  ];

  const experienceList = [
    {
      title: "One account, multiple agents",
      img: experienceImg1,
      alt: "One WhatsApp account, Multi agent access",
      desc: "Empower your agents to handle customer inquiries seamlessly from any device using a single WhatsApp account.",
    },
    {
      title: "Built for SMEs",
      alt: "CRM for small business",
      img: experienceImg2,
      desc: "Capture and convert leads, maintain client profiles, and win sales conversations with best CRM for WhatsApp.",
    },
    {
      title: "Omnichannel CRM",
      alt: "Omnichannel CRM software",
      img: experienceImg3,
      desc: "Integrate email, social media channels, voice calling and more. Unify every touchpoint using Pepper Cloud CRM. ",
    },
    {
      title: "24/7 Local support",
      alt: "Pepper Cloud - 24X7 Local Support",
      img: experienceImg4,
      desc: "Get instant responses to your queries through multiple channels, including messages, calls, and emails.",
    },
  ];
  // When the section is in view, set up a timer to auto change the active accordion every 5 seconds.

  React.useEffect(() => {
    const pcIframe = document.getElementById("pc-iframe");
    pcIframe.src = pcIframe.src + window.location.search;
    if (window.location.search)
      pcIframe.src = pcIframe.src + "&page_title=" + document.title;
    else pcIframe.src = pcIframe.src + "?page_title=" + document.title;
  }, []);
  return (
    <main>
      <SEO
        title={
          "Best B2B AI-powered WhatsApp CRM tool in Singapore | Pepper Cloud"
        }
        pathname={"https://peppercloud.com/crm-partners/tricove-tech"}
        keywords="CRM tool Singapore"
        description={
          "Try Tricove Tech's CRM solution provide partner - Pepper Cloud. Its Singapore's best AI-powered WhatsApp CRM tool to maximise B2B sales productivity."
        }
        ogData={{
          "og:image": [
            "/meta/og/1X1/home.png",
            "/meta/og/1024X512/home.png",
            "/meta/og/1200X630/home.png",
          ],
        }}
        jsonLd={{
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "Pepper Cloud",
          legalName: "Pepper Cloud PTE LTD",
          url: "https://peppercloud.com/",
          logo: "https://peppercloud.com/icons/logo.png",
          foundingDate: "2017",
          address: [
            {
              "@type": "PostalAddress",
              streetAddress: "#04-01/02",
              addressLocality: "Blk 79 Ayer Rajah Crescent",
              addressRegion: "SG",
              postalCode: "139955",
              addressCountry: "Singapore",
            },
            {
              "@type": "PostalAddress",
              streetAddress:
                "2nd Floor, Salarpuria Tower 1, 7th Block Koramangala",
              addressLocality: "Bengaluru",
              addressRegion: "KA",
              postalCode: "560095",
              addressCountry: "India",
            },
          ],
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer support",
            telephone: "+6566562616",
            email: ["sales@peppercloud.com", "support@peppercloud.com"],
          },
          sameAs: [
            "https://twitter.com/peppercloudpte",
            "https://www.linkedin.com/company/peppercloud/",
            "https://www.facebook.com/PepperCloudPteLtd",
            "https://www.instagram.com/pepper.cloud/",
          ],
        }}
      />
      <div className={classes.headerContainer}>
        <div
          className={clsx(
            classes.headerContainerInner,
            scrollPosition > 200 && "glassEffect"
          )}
        >
          <div className={classes.leftNavigation}>
            <Link to="/">
              <img
                src={companyLogo}
                alt="Pepper Cloud - Best Sales CRM Software"
              />
            </Link>
          </div>
          <div className={classes.rightNavigation}>
            <p className="font-16 font-white font-wix-semibold">
              Book a free demo
            </p>
            <a href="tel:+6588060332" onClick={conversionEventOnCall}>
              <img src={callImg} />
              <p className="font-16 font-wix-medium font-white">
                +65 8806 0332
              </p>
            </a>
          </div>
        </div>
      </div>
      <section className={classes.whatsappSectionContainer}>
        <div className="container">
          <div className={classes.whatsappContainer}>
            <div className={classes.whatsappContainerLeft}>
              <motion.h1
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-semibold font-72 color-black1"
              >
                Simplify sales success with best{" "}
                <span className="highlighterText">CRM tool</span>
              </motion.h1>
              <motion.p
                initial={{ opacity: 1, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="font-wix-regular font-20 color-black2"
              >
                Streamline sales, marketing, and customer communication
                processes with Pepper Cloud, the all-in-one CRM tool designed to
                drive business growth. From capturing leads to closing deals,
                this CRM tool offers a seamless way to enhance your operations
                and deliver outstanding customer experiences.
              </motion.p>
              <div className={classes.tagsContainer}>
                <div className={classes.tag}>
                  <img src={greenCheckIcon} alt="pointer" />
                  <p className="font-16 font-black2 font-wix-regular">
                    WhatsApp-integrated
                  </p>
                </div>
                <div className={classes.tag}>
                  <img src={greenCheckIcon} alt="pointer" />
                  <p className="font-16 font-black2 font-wix-regular">
                    AI-driven
                  </p>
                </div>
                <div className={classes.tag}>
                  <img src={greenCheckIcon} alt="pointer" />
                  <p className="font-16 font-black2 font-wix-regular">
                    Built for SMEs
                  </p>
                </div>
                <div className={classes.tag}>
                  <img src={greenCheckIcon} alt="pointer" />
                  <p className="font-16 font-black2 font-wix-regular">
                    Local support 24/7
                  </p>
                </div>
              </div>
              <div className={classes.rewards}>
                <img
                  src={whatsappBusinessPartner}
                  alt="WhatsApp Business Partner"
                />
                <div className="border" />
                <img src={metaBusinessPartner} alt="Meta Business Partner" />
              </div>
            </div>
            <div className={classes.whatsappContainerRight}>
              <div className={classes.formHeader}>
                <p className="font-24 font-white font-wix-semibold">
                  Avail up to <span>50%</span> PSG Grant
                </p>
              </div>
              <div className={classes.formContainer}>
                <iframe
                  width={"100%"}
                  id="iframe"
                  height={"570px"}
                  style={{ border: "none" }}
                  src="https://app.peppercloud.com/form/56321823-b4e2-4142-acec-dec052149ffd/embed"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={classes.broadcastContainer}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2 className="font-24 font-wix-medium font-white text-center">
              Best AI-driven WhatsApp CRM tool - Trusted by businesses
              everywhere
            </motion.h2>
          </div>
        </div>
        <Swiper
          className={classes.partnerSlider}
          slidesPerView="auto"
          spaceBetween={80}
          loop={true}
          speed={2000}
          modules={[Autoplay]}
          allowTouchMove={false}
          autoplay={{
            delay: 100,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
          }}
        >
          {partners.map((partner) => (
            <SwiperSlide className={classes.partnerSlide}>
              <img src={partner.icon} alt={partner.alt} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-semibold font-56 font-white"
            >
              Why Pepper Cloud is the best CRM tool for your business
            </motion.h2>
          </div>
        </div>
        <Swiper
          // slidesPerView={"1.1"}
          // spaceBetween={"20px"}
          // slidesPerGroup={"1.1"}
          // slideActiveClass="active"
          // slidePrevClass="inActive"
          // slideNextClass="inActive"
          slidesPerView={1.1}
          centeredSlides={true}
          spaceBetween={30}
          grabCursor={true}
          // freeMode={true}
          className={classes.broadcastContainerInner}
          breakpoints={{
            // For smaller screens (mobile)
            0: {
              slidesPerView: 1.1,
              slidesPerGroup: 1,
              freeMode: true,
              grabCursor: false,
              allowTouchMove: true,
              spaceBetween: 10,
            },
            // For larger screens (desktop)
            768: {
              slidesPerView: 1.1,
              centeredSlides: true,
              spaceBetween: 30,
              grabCursor: true,
            },
          }}
        >
          {broadcastList.map((point, index) => (
            <SwiperSlide
              className={classes.broadcastBox}
              style={{ background: point.background }}
            >
              <div className={classes.broadcastLeftContainer}>
                <p className="font-40 font-wix-medium font-white">
                  {point.heading}
                </p>
                <div className={classes.pointers}>
                  {point.points.map((content) => (
                    <div className={classes.cardPointer}>
                      <LazyImage
                        source={whiteCheckCircle}
                        className={classes.pointerImg}
                        alt={"pointer"}
                      />
                      <p className="font-wix-regular font-18 font-white">
                        {content}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className={classes.broadcastRightContainer}>
                <img
                  src={point.img}
                  alt={point.alt}
                  className={classes.broadcastImg}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={classes.belowBroadcast}>
          <div className="container">
            <p className="font-18 font-wix-regular font-white">
              Tricove Tech, an official reseller of Pepper Cloud CRM, helps
              businesses like yours access and implement the CRM tool seamlessly
              and grow sales multifold.
            </p>
          </div>
        </div>
      </section>
      <section className={classes.sellSection}>
        <div className="container">
          <div className={classes.sellSmartHeader}>
            <motion.h1 className="font-56 font-wix-semibold color-black1">
              Sell smarter with the key features of the CRM tool
            </motion.h1>
            <motion.p className="font-18 font-wix-regular color-black1">
              Empower your sales and marketing teams to speed up deal closure,
              reduce response times, streamline workflows, and boost sales with
              powerful features.
            </motion.p>
          </div>
        </div>
        <Swiper
          // slidesPerView={"1.1"}
          // spaceBetween={"20px"}
          // slidesPerGroup={"1.1"}
          // slideActiveClass="active"
          // slidePrevClass="inActive"
          // slideNextClass="inActive"
          slidesPerView={"3.2"}
          //   centeredSlides={true}
          spaceBetween={30}
          grabCursor={true}
          freeMode={true}
          className={classes.sellSwiper}
          breakpoints={{
            //   For smaller screens (mobile)
            0: {
              slidesPerView: "auto",
              freeMode: true,
              grabCursor: false,
              spaceBetween: 30,
            },
            // For larger screens (desktop)
            768: {
              slidesPerView: "3.2",
              spaceBetween: 30,
              grabCursor: true,
            },
            // 1700: {
            //   slidesPerView: 3.2,
            // },
          }}
        >
          {sellSmartList.map((point, index) => (
            <SwiperSlide
              className={classes.sellCard}
              style={{ background: point.bgColor }}
            >
              <img src={point.icon} alt={point.heading} />
              <p className="font-24 font-wix-semibold color-black1">
                {point.heading}
              </p>
              <ul className={classes.salesList}>
                {point.points.map((pointer) => (
                  <li className={classes.salesListItem}>
                    <img src={greenCheckIcon} alt="greenCheck" />
                    <p className="font-wix-regular font-16 color-black1">
                      {pointer}
                    </p>
                  </li>
                ))}
              </ul>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      <section className={classes.aIPowered}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="font-wix-semibold font-56 font-white"
            >
              Get immediate results with top CRM tool
            </motion.h2>
          </div>
          <div className={classes.statsContainer}>
            {statsBoxContent.map((stat, index) => (
              <motion.div
                whileInView={{ opacity: [0, 1], y: [50, 0] }}
                transition={{ duration: 1, delay: index * 0.1 }}
                className={classes.statsBox}
              >
                <p className={clsx(classes.statsFont, "font-wix-medium")}>
                  {stat.number}{" "}
                  <span className="font-56 font-wix-medium">%</span>
                </p>
                <div className="border" />
                {/* <div className={classes.statsBoxHeader}>
                 
                </div> */}
                <div className={classes.statsBoxBody}>
                  <p className="font-18 color-purple3 font-wix-semibold">
                    {stat.heading}
                  </p>
                  <p className="font-16 color-purple3 font-wix-regular">
                    {stat.content}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
          <div className={classes.belowText}>
            <p className="font-12 font-wix-regular">
              * Statistics from multiple sources
            </p>
          </div>
        </div>
      </section>
      <section className={classes.smeSection}>
        <div className="container">
          <div className={classes.smeSectionInner}>
            <img
              src={IMDA_SME_Large}
              className={classes.smeImage}
              alt="IMDA Pre-approved Solution Provider"
              onClick={() =>
                window.open(
                  "https://services2.imda.gov.sg/CTOaaS/DigitalHealthCheck?utm_source=PA+vendor&utm_medium=vendor+site&utm_campaign=SN222"
                )
              }
            />
            <motion.p className="font-18 font-wix-medium color-black1">
              SMEs are eligible for up to 50% Productivity Solutions Grant (PSG)
              support for the adoption of Pepper Cloud CRM solution, a
              Pre-Approved Solution under the IMDA SMEs Go Digital programme.
            </motion.p>
          </div>
        </div>
      </section>
      <div className={classes.wpFaq}>
        <FaqSection
          backgroundColor={"#F9F5FC"}
          heading={"Frequently asked questions All-in-one CRM tool"}
          faqs={faqs}
        />
      </div>
      <section className={classes.commonPageFooterSection}>
        <div className="container">
          <div className="centerContainer">
            <motion.h2
              initial={{ opacity: 1, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="font-wix-medium font-56 font-white"
            >
              Start your journey with the best CRM tool
            </motion.h2>
            <button
              className="primaryButton font-20 font-wix-medium font-white"
              onClick={() => scrollToTop()}
            >
              Get started
            </button>
          </div>
        </div>
      </section>
      <div className={classes.pageFooter}>
        <div className="container">
          <p className="font-14 font-wix-regular">
            © Pepper Cloud 2025 - Best AI-powered WhatsApp Sales CRM Software
            Singapore
          </p>
          {/* <Link className="font-14 font-wix-regular">Site map</Link> */}
        </div>
      </div>
    </main>
  );
};

export default TricoPage;
